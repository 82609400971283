import React from "react";
import { Dialog, Menu, MenuItem, Spinner } from "@blueprintjs/core";
import { styled } from "linaria/react";
import useLocations from "_/graphql/queries/useLocations";
import { GetLocations_locations } from "_/graphql/queries/__generated__/GetLocations";
import { setLocation } from "_/pages/tours/effector/location";

const Location: React.FC<{ item: GetLocations_locations }> = ({ item }) => {
  const onClick = React.useCallback(() => {
    setLocation(item.id);
  }, [item.id]);
  return <MenuItem text={item.name} onClick={onClick} />;
};

const UnstyledLocationDialog: React.FC<{
  className?: string;
  isOpen: boolean;
}> = ({ className, isOpen }) => {
  const { data } = useLocations();
  return (
    <Dialog
      isOpen={isOpen}
      icon="map"
      title={"Chose location"}
      isCloseButtonShown={false}
      className={className}
    >
      {data ? (
        <Menu large={true}>
          {data.locations.map((loc) => (
            <Location key={loc.id} item={loc} />
          ))}
        </Menu>
      ) : (
        <Spinner />
      )}
    </Dialog>
  );
};

const LocationDialog = styled(UnstyledLocationDialog)`
  &.bp3-dialog {
    padding: 0;
  }
`;

export default LocationDialog;
