import { combine, Event, Store } from "effector";
import AddFilter from "_/components/Header/AddFilter";
import keyFor from "_/utils/keyFor";
import CancelFilter from "_/components/Header/CancelFilter";
import {
  $allLocations,
  $allManagers,
  $allOrderDates,
  $allTourDates,
  $allTours,
  $selectedLocation,
  $selectedManager,
  $selectedOrderDate,
  $selectedTour,
  $selectedTourDate,
  selectLocation,
  selectManager,
  selectOrderDate,
  selectTour,
  selectTourDate,
} from "_/effector/statistics";
import React from "react";

interface IFilter<
  TName extends string,
  TId extends number | string,
  TItem extends { id: TId; name: string }
> {
  name: TName;
  add: React.ReactElement;
  cancel: React.ReactElement;
  valueStore: Store<TItem | null>;
}

function createFilter<
  TName extends string,
  TId extends number | string,
  TItem extends { id: TId; name: string }
>(
  name: TName,
  defaultOption: { id: TId; name: string },
  valueStore: Store<TItem | null>,
  optionsStore: Store<TItem[]>,
  onChange: Event<TId | null>
): IFilter<TName, TId, TItem> {
  return {
    name,
    add: (
      <AddFilter
        key={keyFor(onChange)}
        optionsStore={optionsStore}
        valueStore={valueStore}
        onChange={onChange}
        defaultOption={defaultOption}
      />
    ),
    cancel: (
      <CancelFilter
        key={keyFor(onChange)}
        onChange={onChange}
        valueStore={valueStore}
      />
    ),
    valueStore,
  };
}

const filters = [
  createFilter(
    "manager",
    { id: 0, name: "All managers" },
    $selectedManager,
    $allManagers,
    selectManager
  ),
  createFilter(
    "location",
    { id: 0, name: "All locations" },
    $selectedLocation,
    $allLocations,
    selectLocation
  ),
  createFilter(
    "tour",
    { id: 0, name: "All tours" },
    $selectedTour,
    $allTours,
    selectTour
  ),
  createFilter(
    "tour_date",
    { id: "", name: "All dates" },
    $selectedTourDate,
    $allTourDates,
    selectTourDate
  ),
  createFilter(
    "order_date",
    { id: "", name: "Created at" },
    $selectedOrderDate,
    $allOrderDates,
    selectOrderDate
  ),
] as const;

type Filters = typeof filters[number];

type Shape = {
  [K in Filters as K["name"]]: K["valueStore"];
};

const filtersShape = Object.fromEntries(
  filters.map((filter) => [filter.name, filter.valueStore])
) as Shape;

export const $filterName = combine(filtersShape, (values) => {
  const parts = Object.values(values)
    .map((v) => (typeof v === "string" ? v : v?.name))
    .filter(Boolean);
  return parts.length ? parts.join(" ") : "All orders";
});

export default filters;
