import { LoadAllOrdersQuery_booking_all_orders_tour_tour } from "_/effector/__generated__/LoadAllOrdersQuery";

export function getTourTitle(
  tour: LoadAllOrdersQuery_booking_all_orders_tour_tour
) {
  const place = tour.place.name;
  const title = tour.title;
  if (title.toLocaleLowerCase().includes(place.toLocaleLowerCase())) {
    return title;
  }

  return `${place} / ${title}`;
}
