import React from "react";

import OverlaySpinner from "./OverlaySpinner";
import { useStore } from "effector-react";
import { $page } from "_/effector/router";
import ToursPage from "_/pages/tours";
import StatisticsPage from "_/pages/statistics";

const pages = {
  statistics: StatisticsPage,
  tours: ToursPage,
};

const MainView: React.FC = () => {
  const page = useStore($page);

  const PageCmp = pages[page];

  return (
    <React.Suspense fallback={<OverlaySpinner />}>
      <PageCmp />
    </React.Suspense>
  );
};

export default MainView;
