import { HTMLSelect } from "@blueprintjs/core";
import { DateInput, IDateFormatProps } from "@blueprintjs/datetime";
import React from "react";
import { format, parse } from "date-fns";

import { $period, setDate, setDays } from "_/pages/tours/effector/period";
import { useStore } from "effector-react";

const jsDateFormatter: IDateFormatProps = {
  formatDate: (date: Date) => date.toLocaleDateString(),
  parseDate: (str: string) => new Date(str),
  placeholder: "DD.MM.YYYY",
};

const options = Array(26)
  .fill(5)
  .map((v, idx) => v + idx);

const DatesSelector: React.FC = () => {
  const onChangeDate = React.useCallback((value: Date | null) => {
    if (value === null) return;
    const formatted = format(value, "yyyy-MM-dd");
    window.localStorage.setItem("date", formatted);
    setDate(formatted);
  }, []);

  const onChangeLength = React.useCallback<
    React.ChangeEventHandler<HTMLSelectElement>
  >((event) => {
    window.localStorage.setItem("length", event.target.value);
    setDays(parseInt(event.target.value, 10));
  }, []);

  const [date, length] = useStore($period);
  const dateObj = React.useMemo(
    () => parse(date, "yyyy-MM-dd", new Date()),
    [date]
  );
  return (
    <>
      <DateInput {...jsDateFormatter} value={dateObj} onChange={onChangeDate} />
      <HTMLSelect
        minimal={true}
        value={undefined}
        onChange={onChangeLength}
        defaultValue={length}
      >
        {options.map((item) => (
          <option key={item} value={item}>
            {item} days
          </option>
        ))}
      </HTMLSelect>
    </>
  );
};

export default DatesSelector;
