import React from "react";

import Auth from "./components/Auth";
import MainView from "./components/MainView";

function App() {
  return (
    <Auth>
      <MainView />
    </Auth>
  );
}

export default App;
