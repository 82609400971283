import { Alignment, Button, Navbar } from "@blueprintjs/core";
import React from "react";

import "./styles.css";
import CustomerEditor from "./CustomerEditor";
import Voucher from "./Voucher";
import { IAnyCustomer, resetCustomer } from "_/pages/tours/effector/customer";

const SelectedCustomer: React.FC<{ customer: IAnyCustomer }> = ({
  customer,
}) => {
  return (
    <>
      <Navbar.Group>
        <Button
          minimal={true}
          rightIcon="cross"
          onClick={() => resetCustomer()}
        />
        <Navbar.Heading>{customer.name}</Navbar.Heading>
        <CustomerEditor
          key={customer.id}
          className="customer-editor"
          customer={customer}
        />
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        <Navbar.Divider />
        <Voucher />
      </Navbar.Group>
    </>
  );
};

export default SelectedCustomer;
