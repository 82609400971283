import { Button, Classes, Navbar } from "@blueprintjs/core";
import React from "react";
import { useStore } from "effector-react";
import { styled } from "linaria/react";

import { $currentUserName, logout } from "_/effector/auth";
import { $page, changePage } from "_/effector/router";

const StyledNavbar = styled(Navbar)`
  display: flex;
  justify-content: space-between;
  min-width: 800px;

  @media screen and (max-width: 1000px) {
    :global {
      .view-container {
        position: static;
      }
    }

    &&.${Classes.FIXED_TOP} {
      position: absolute;
    }
  }
`;

const backLinks = {
  statistics: <Button onClick={() => changePage("tours")}>Tours</Button>,
  tours: <Button onClick={() => changePage("statistics")}>Statistics</Button>,
};

const BaseHeader: React.FC<{
  firstLine?: React.ReactNode;
  secondLine?: React.ReactNode;
}> = ({ firstLine, secondLine }) => {
  const userName = useStore($currentUserName);
  const page = useStore($page);
  const onClickHandler = React.useCallback(() => logout(), []);
  return (
    <>
      <StyledNavbar fixedToTop={true}>
        <Navbar.Group>
          <Navbar.Heading>EasyTravel</Navbar.Heading>
          {firstLine && (
            <>
              <Navbar.Divider />
              {firstLine}
            </>
          )}
        </Navbar.Group>
        <Navbar.Group>
          {backLinks[page]}
          <Navbar.Divider />
          {userName && (
            <Button
              minimal={true}
              rightIcon="log-out"
              text={userName}
              onClick={onClickHandler}
            />
          )}
        </Navbar.Group>
      </StyledNavbar>
      {secondLine && (
        <StyledNavbar fixedToTop={true}>{secondLine}</StyledNavbar>
      )}
    </>
  );
};

export default BaseHeader;
