import { HTMLSelect } from "@blueprintjs/core";
import React from "react";

import useLocations from "../../graphql/queries/useLocations";
import { $location, setLocation } from "../../pages/tours/effector/location";
import { useStore } from "effector-react";

const LocationSelector: React.FC = () => {
  const { data } = useLocations();
  const allLocations = data?.locations ?? [];
  const locationId = useStore($location);
  const onChange = React.useCallback(
    (ev: React.ChangeEvent<HTMLSelectElement>) => {
      const id = ev.target.value ? parseInt(ev.target.value, 10) : null;
      setLocation(id);
    },
    []
  );
  return (
    <HTMLSelect
      minimal={true}
      value={locationId ?? undefined}
      onChange={onChange}
    >
      {allLocations.map((loc) => (
        <option key={loc.id} value={loc.id}>
          {loc.name}
        </option>
      ))}
    </HTMLSelect>
  );
};

export default LocationSelector;
