import { gql, useMutation } from "@apollo/client";

import {
  UpdateCustomer,
  UpdateCustomerVariables,
} from "./__generated__/UpdateCustomer";
import { MutationHookOptions } from "@apollo/client/react/types/types";

const updateCustomer = gql`
  mutation UpdateCustomer(
    $id: ID!
    $name: String!
    $hotelId: Int!
    $adults: Int!
    $children: Int!
    $infants: Int!
  ) {
    updateCustomer(
      id: $id
      customer: {
        name: $name
        hotelId: $hotelId
        adults: $adults
        children: $children
        infants: $infants
      }
    ) {
      id
      rev
    }
  }
`;

export default function useUpdateCustomer(
  options?: MutationHookOptions<UpdateCustomer, UpdateCustomerVariables>
) {
  return useMutation<UpdateCustomer, UpdateCustomerVariables>(
    updateCustomer,
    options
  );
}
