import { gql, useQuery } from "@apollo/client";
import { QueryHookOptions } from "@apollo/client/react/types/types";
import { GetLocations } from "./__generated__/GetLocations";

export const getLocations = gql`
  query GetLocations {
    locations: booking_locations {
      id
      name
    }
  }
`;

export default function useLocations(options?: QueryHookOptions<GetLocations>) {
  return useQuery<GetLocations>(getLocations, options);
}
