import { ApolloProvider } from "@apollo/client";
import React from "react";

import AuthDialog from "./AuthDialog";
import client from "../../graphql/client";
import { $authToken } from "_/effector/auth";
import { useStore } from "effector-react";

const Auth: React.FC = ({ children }) => {
  const token = useStore($authToken);
  if (token === null) {
    return <AuthDialog />;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Auth;
