import React, { CSSProperties } from "react";
import { cx } from "linaria";
import { Popover, Switch } from "@blueprintjs/core";
import OrderEditor from "./OrderEditor";
import { customerClass, Target } from "./styled";
import CellContent from "./CellContent";
import useCellHandlers from "./useCellHandlers";
import { TourPrice } from "_/pages/tours/effector/__generated__/TourPrice";

const CellWithoutOrder: React.FC<{
  capacity: number;
  reserve: number;
  className?: string;
  id: number;
  saveOrder: () => void;
  style: CSSProperties;
  prices: TourPrice[];
  time: string;
}> = React.memo(
  ({ capacity, className, id, prices, reserve, style, saveOrder, time }) => {
    const targetClassName = cx("tours-table_time-slot", customerClass);

    const handleSave = React.useCallback(
      () => (capacity > 0 ? saveOrder() : void 0),
      [capacity, saveOrder]
    );
    const handlers = useCellHandlers(handleSave);

    return (
      <Popover openOnTargetFocus className={className}>
        <Target
          style={style}
          className={targetClassName}
          role="checkbox"
          aria-checked="false"
          tabIndex={0}
          onKeyDown={handlers.onKeyDown}
        >
          <CellContent capacity={capacity} time={time}>
            <Switch
              disabled={capacity < 0}
              tabIndex={-1}
              inline={true}
              checked={false}
              onChange={handlers.onChange}
            />{" "}
          </CellContent>
        </Target>
        <OrderEditor slotId={id} prices={prices} max={capacity + reserve} />
      </Popover>
    );
  }
);

export default CellWithoutOrder;
