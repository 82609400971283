import React from "react";
import Statistics from "_/components/Statistics";
import View from "_/components/View";
import BaseHeader from "_/components/BaseHeader";
import { Divider, Navbar } from "@blueprintjs/core";
import { useStore } from "effector-react";
import { $lastRevOrders, fetchAllOrdersFx } from "_/effector/statistics";
import Download from "./Download";
import filters from "./filters";
import OverlaySpinner from "_/components/OverlaySpinner";

const Total = () => {
  const lastRevOrders = useStore($lastRevOrders);
  const total = lastRevOrders.reduce(
    (acc, order) => acc + parseFloat(order.cost),
    0
  );
  return (
    <Navbar.Group>
      <h3>
        Total: <b>€{total}</b> for <b>{lastRevOrders.length}</b>{" "}
        {lastRevOrders.length === 1 ? "order" : "orders"}.
      </h3>
    </Navbar.Group>
  );
};

const StatisticsPage: React.FC = () => {
  const isLoading = useStore(fetchAllOrdersFx.pending);

  const firstLine = (
    <>
      {filters.map((filter) => filter.cancel)}
      <Divider />
      <Download />
    </>
  );

  const secondLine = (
    <>
      <Navbar.Group>{filters.map((filter) => filter.add)}</Navbar.Group>
      <Total />
    </>
  );

  return (
    <View navbar={<BaseHeader firstLine={firstLine} secondLine={secondLine} />}>
      {isLoading ? <OverlaySpinner /> : <Statistics />}
    </View>
  );
};

export default StatisticsPage;
