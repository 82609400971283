import { Button, MenuItem } from "@blueprintjs/core";
import { IItemRendererProps, Select } from "@blueprintjs/select";
import React from "react";

import Customer from "./Customer";
import useCustomers from "_/graphql/queries/useCustomers";
import { useStore } from "effector-react";
import { $location } from "_/pages/tours/effector/location";
import { IAnyCustomer, setCustomer } from "_/pages/tours/effector/customer";

const CustomerSelect = Select.ofType<IAnyCustomer>();

const itemRenderer = (
  customer: IAnyCustomer & { manager?: { name: string } },
  itemProps: IItemRendererProps
) => (
  <MenuItem
    key={customer.id}
    active={itemProps.modifiers.active}
    disabled={itemProps.modifiers.disabled}
    onClick={itemProps.handleClick}
    text={<Customer customer={customer} {...itemProps} />}
  />
);

function createCustomer(name: string): IAnyCustomer {
  return {
    id: "new",
    rev: -1,
    name,
    hotel: null,
    adults: 2,
    children: 0,
    infants: 0,
  };
}

function renderCreateCustomerOption(
  query: string,
  active: boolean,
  handleClick: React.MouseEventHandler<HTMLElement>
) {
  return (
    <MenuItem
      icon="add"
      text={`Add "${query}"`}
      active={active}
      onClick={handleClick}
      shouldDismissPopover={false}
    />
  );
}

const CustomerSelector: React.FC = () => {
  const [filter, setFilter] = React.useState<string | null>(null);
  const locationId = useStore($location)!;

  const response = useCustomers({
    variables: {
      locationId,
      filter,
    },
    fetchPolicy: "cache-and-network",
  });

  const customers = response.data?.customers;

  return (
    <>
      <CustomerSelect
        createNewItemFromQuery={createCustomer}
        createNewItemRenderer={renderCreateCustomerOption}
        items={customers ?? []}
        itemRenderer={itemRenderer}
        onItemSelect={setCustomer}
        onQueryChange={setFilter}
        resetOnSelect={true}
      >
        <Button text={"Choose customer"} rightIcon="user" />
      </CustomerSelect>
    </>
  );
};

export default CustomerSelector;
