import { onError } from "@apollo/client/link/error";
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  concat,
} from "@apollo/client";
import { $authToken } from "_/effector/auth";

const cache = new InMemoryCache({
  typePolicies: {
    TourOrder: {
      keyFields: ["id", "rev"],
    },
    TourOrderInStatistic: {
      keyFields: ["id", "rev"],
    },
  },
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = $authToken.getState();

  if (token) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  }

  return forward(operation);
});

const httpLink = new HttpLink({
  uri: "/graphql",
});

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach((error) => {
      if (error.extensions?.code === "UNAUTHENTICATED") {
        window.localStorage.removeItem("auth-token");
        window.sessionStorage.clear();
        window.location.reload();
      }
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
}).concat(httpLink);

export default new ApolloClient({
  link: concat(authMiddleware, onErrorLink),
  cache: cache,
});
