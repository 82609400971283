import React from "react";

import "./view.css";
import { cx } from "linaria";

const View: React.FC<{ oneLiner?: boolean; navbar: React.ReactNode }> = ({
  children,
  navbar,
  oneLiner = false,
}) => {
  return (
    <div className={cx("view-container", oneLiner && "one-liner")}>
      {navbar}
      <div className="view-content">{children}</div>
    </div>
  );
};

export default View;
