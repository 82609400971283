import { gql, useMutation } from "@apollo/client";

import { AddCustomer, AddCustomerVariables } from "./__generated__/AddCustomer";
import { MutationHookOptions } from "@apollo/client/react/types/types";

const addCustomer = gql`
  mutation AddCustomer(
    $name: String!
    $hotelId: Int!
    $adults: Int!
    $children: Int!
    $infants: Int!
  ) {
    addCustomer(
      customer: {
        name: $name
        hotelId: $hotelId
        adults: $adults
        children: $children
        infants: $infants
      }
    ) {
      id
      rev
    }
  }
`;

export default function useAddCustomer(
  options?: MutationHookOptions<AddCustomer, AddCustomerVariables>
) {
  return useMutation<AddCustomer, AddCustomerVariables>(addCustomer, options);
}
