import React from "react";
import { styled } from "linaria/react";
import { HTMLTable, Spinner } from "@blueprintjs/core";
import {
  GetParticipants_tour_checkpoints_orders,
  GetParticipants_tour_checkpoints_vouchers,
  GetParticipants_tour_prices,
} from "./__generated__/GetParticipants";

import { ICheckpoint } from "./types";
import { addMinutes } from "_/utils/addMinutes";
import { useListOfParticipant } from "./useListOfParticipant";

const Price = styled.div`
  display: flex;
  justify-content: space-between;
  & > span:last-child {
    margin-left: 1em;
    text-align: right;
  }
`;

const CustomerInfo = styled.div`
  display: flex;

  & > span {
    margin-right: 1em;

    &:first-child {
      font-weight: bold;
      white-space: initial;
    }

    &:last-child {
      margin-right: 0;
      margin-left: auto;
    }
  }
`;

const Table = styled(HTMLTable)`
  width: 100%;

  & > thead h3 {
    display: flex;
    margin: 0;
    justify-content: space-between;
  }

  & > tbody {
    white-space: nowrap;
  }

  & > tfoot td:last-child {
    border-top: 2px solid var(--gray3);
  }
`;

const Order: React.FC<{
  point: string;
  prices: GetParticipants_tour_prices[];
  order: GetParticipants_tour_checkpoints_orders;
  vouchers: GetParticipants_tour_checkpoints_vouchers[];
}> = ({ prices, order, vouchers }) => {
  const customer = order.customer;
  const voucher = vouchers.find((v) => v.customerId === customer.id);
  return (
    <tr>
      <td>
        <CustomerInfo>
          <span>{customer.name}</span>
          {voucher?.data.phone && <span>{voucher.data.phone}</span>}
          <span>{order.manager.name}</span>
        </CustomerInfo>
        <div>{order.customer.hotel.name}</div>
      </td>
      <td>
        {prices
          .filter((price) => order.data[price.id] > 0)
          .map((price) => (
            <Price key={price.id}>
              <span>{price.slug}</span>
              <span>{order.data[price.id]}</span>
            </Price>
          ))}
      </td>
    </tr>
  );
};

const Checkpoint: React.FC<{
  baseTime: string;
  checkpoint: ICheckpoint;
  prices: GetParticipants_tour_prices[];
}> = ({ baseTime, checkpoint, prices }) => {
  if (checkpoint.orders.length === 0) return null;
  return (
    <>
      <thead>
        <tr>
          <th colSpan={2}>
            <h3>
              {checkpoint.point}{" "}
              <span>{addMinutes(baseTime, checkpoint.delta)}</span>
            </h3>
          </th>
        </tr>
      </thead>
      <tbody>
        {checkpoint.orders.map((order) => (
          <Order
            key={order.id}
            point={checkpoint.point}
            prices={prices}
            order={order}
            vouchers={checkpoint.vouchers}
          />
        ))}
      </tbody>
    </>
  );
};

const ListOfParticipant: React.FC<{ id: number; title: string }> = ({ id }) => {
  const { isLoading, data, summary, checkpoints } = useListOfParticipant(id);

  if (isLoading) {
    return <Spinner />;
  }

  if (!data) {
    return <div>Something went wrong…</div>;
  }

  const total = Object.values(summary ?? {}).reduce((acc, p) => acc + p, 0);

  return (
    <>
      <Table striped={true} bordered={true}>
        {checkpoints.map((checkpoint) => (
          <Checkpoint
            key={checkpoint.key}
            checkpoint={checkpoint}
            baseTime={data.tour.time}
            prices={data.tour.prices}
          />
        ))}
        <tfoot>
          <tr>
            <td />
            <td>
              {data.tour.prices.map((price) => (
                <Price key={price.id}>
                  <span>{price.slug}</span>
                  <span>{summary?.[price.id]}</span>
                </Price>
              ))}
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <Price key="total">
                <span>Total</span>
                <span>{total}</span>
              </Price>
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

export default ListOfParticipant;
