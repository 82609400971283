import { gql, useQuery } from "@apollo/client";
import {
  GetParticipants,
  GetParticipantsVariables,
} from "./__generated__/GetParticipants";
import React from "react";
import { groupBy, map } from "lodash";
import { ORDER_REF_FRAGMENT } from "_/pages/tours/effector/orders";
import {
  SLOT_FRAGMENT,
  TOUR_PRICE_FRAGMENT,
} from "_/pages/tours/effector/tours";

const GET_PARTICIPANTS = gql`
  ${ORDER_REF_FRAGMENT}
  ${SLOT_FRAGMENT}
  ${TOUR_PRICE_FRAGMENT}
  query GetParticipants($tourId: Int!) {
    tour: booking_tour(tourId: $tourId) {
      ...TourSlot
      route_id
      description_id
      prices {
        ...TourPrice
      }
      date
      time
      tour {
        title
      }
      checkpoints {
        id
        hotel_id
        delta
        title
        hotel {
          name
        }
        orders {
          ...OrderRef
          data
          manager {
            name
          }
          customer {
            id
            hotel {
              name
            }
            name
          }
        }
        vouchers {
          customerId
          data {
            phone
          }
        }
      }
    }
  }
`;

export const useListOfParticipant = (id: number) => {
  const response = useQuery<GetParticipants, GetParticipantsVariables>(
    GET_PARTICIPANTS,
    {
      fetchPolicy: "network-only",
      variables: {
        tourId: id,
      },
    }
  );

  const data = response.data;
  const summary = React.useMemo(
    () =>
      data?.tour.checkpoints
        .flatMap((checkpoint) => checkpoint.orders)
        .reduce(
          (acc, order) => {
            Object.keys(order.data).forEach((key) => {
              acc[key] += order.data[key] as number;
            });

            return acc;
          },
          data.tour.prices.reduce(
            (acc, price) => ({ ...acc, [price.id]: 0 }),
            {} as Record<string, number>
          )
        ),
    [data]
  );

  const checkpoints = React.useMemo(
    () =>
      map(
        groupBy(
          data?.tour.checkpoints ?? [],
          (cp) => `${cp.delta}|${cp.title || cp.hotel.name}`
        ),
        (cp, key) => {
          const [delta, point] = key.split("|");
          return {
            key,
            delta: parseInt(delta, 10) || 0,
            orders: cp
              .flatMap((c) => c.orders)
              .sort((a, b) => a.customer.name.localeCompare(b.customer.name)),
            vouchers: cp.flatMap((c) => c.vouchers),
            point,
          };
        }
      ).sort((a, b) => a.delta - b.delta),
    [data]
  );

  return {
    data,
    summary,
    checkpoints,
    isLoading: response.loading,
  };
};
