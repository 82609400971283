import { gql } from "@apollo/client";
import { createEffect, createStore, merge } from "effector";
import client from "_/graphql/client";
import { showToast } from "_/components/Toaster";
import { reloadTours } from "_/pages/tours/effector/tours";
import { Order } from "_/pages/tours/effector/__generated__/Order";
import {
  LoadOrders,
  LoadOrdersVariables,
} from "_/pages/tours/effector/__generated__/LoadOrders";
import {
  SaveOrder,
  SaveOrderVariables,
} from "_/pages/tours/effector/__generated__/SaveOrder";

export type IOrder = Omit<Order, "data"> & {
  data: Record<number, number> | null;
};

export const ORDER_REF_FRAGMENT = gql`
  fragment OrderRef on TourOrder {
    id
    rev
  }
`;

export const ORDER_FRAGMENT = gql`
  fragment Order on TourOrder {
    id
    rev
    customerId
    customerRev
    tourId
    cost
    data
  }
`;

const LOAD_ORDERS = gql`
  ${ORDER_FRAGMENT}
  query LoadOrders($customerId: ID!) {
    customer: booking_customer(customerId: $customerId) {
      orders {
        ...Order
      }
    }
  }
`;

const SAVE_ORDER = gql`
  ${ORDER_FRAGMENT}
  mutation SaveOrder(
    $customerId: ID!
    $customerRev: Int!
    $tourId: Int!
    $order: JSON
  ) {
    saveOrder(
      customerId: $customerId
      customerRev: $customerRev
      tourId: $tourId
      order: $order
    ) {
      ...Order
      tour {
        id
        capacity
        booked
      }
    }
  }
`;

export const loadOrdersFx = createEffect(
  async ({ customerId }: { customerId: string | null }) => {
    if (customerId === null) return [];

    const result = await client.query<LoadOrders, LoadOrdersVariables>({
      query: LOAD_ORDERS,
      variables: {
        customerId,
      },
      fetchPolicy: "network-only",
    });

    return result.data?.customer.orders ?? [];
  }
);

export const saveOrderFx = createEffect(
  async ({
    tourId,
    customerId,
    customerRev,
    data,
  }: Pick<IOrder, "tourId" | "customerId" | "customerRev" | "data">) => {
    const result = await client.mutate<SaveOrder, SaveOrderVariables>({
      mutation: SAVE_ORDER,
      variables: {
        order: data,
        tourId,
        customerId,
        customerRev,
      },
    });

    reloadTours();

    return result.data?.saveOrder!;
  }
);

export const deleteOrderFx = createEffect(
  async ({
    tourId,
    customerId,
    customerRev,
  }: Pick<IOrder, "tourId" | "customerId" | "customerRev">) => {
    const result = await client.mutate<SaveOrder, SaveOrderVariables>({
      mutation: SAVE_ORDER,
      variables: {
        order: null,
        tourId,
        customerId,
        customerRev,
      },
    });

    reloadTours();

    return result.data?.saveOrder!;
  }
);

export const $orders = createStore<IOrder[]>([])
  .on(loadOrdersFx.doneData, (_, orders) => orders)
  .on(saveOrderFx.doneData, (orders, savedOrder) => {
    const result = orders.filter((order) => order.id !== savedOrder.id);
    result.push(savedOrder);
    return result;
  })
  .on(deleteOrderFx.doneData, (orders, deletedOrder) => {
    return orders.filter((order) => order.id !== deletedOrder.id);
  });

merge([saveOrderFx.failData, deleteOrderFx.failData]).watch((error) => {
  showToast({
    icon: "warning-sign",
    intent: "danger",
    message: error.message,
  });
});
