import { restore, createEffect, createEvent, combine } from "effector";
import { gql } from "@apollo/client";
import { flatten, groupBy, sortBy, values, uniqBy, uniq } from "lodash";
import client from "_/graphql/client";
import { CUSTOMER_FRAGMENT } from "_/graphql/queries/useCustomers";
import { persist } from "effector-storage/session";
import {
  LoadAllOrdersQuery,
  LoadAllOrdersQuery_booking_all_orders,
} from "_/effector/__generated__/LoadAllOrdersQuery";
import { $page } from "_/effector/router";

const ORDER_FRAGMENT = gql`
    fragment OrderInStatistic on TourOrderInStatistic {
        id
        rev
        customerId
        customerRev
        tourId
        cost
        delta
        data
    }
`;

const LOAD_ALL_ORDERS = gql`
  ${ORDER_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
  query LoadAllOrdersQuery {
    booking_all_orders {
      ...OrderInStatistic
      manager {
        id
        name
      }
      createdAt
      customer {
        ...Customer
      }
      tour {
        date
        time
        tour {
          id
          place {
            id
            name
          }
          title
          name: title
        }
        prices {
          id
          slug
        }
      }
    }
  }
`;

export const fetchAllOrdersFx = createEffect(async () => {
  const result = await client.query<LoadAllOrdersQuery>({
    query: LOAD_ALL_ORDERS,
    fetchPolicy: "network-only",
  });

  return result.data?.booking_all_orders ?? [];
});

export const $allOrders = restore(fetchAllOrdersFx, []);

export const selectManager = createEvent<number | null>();
export const $selectedManagerId = restore(selectManager, null);
persist({ store: $selectedManagerId, key: "statistics:selectedManager" });

export const selectLocation = createEvent<number | null>();
export const $selectedLocationId = restore(selectLocation, null);
persist({ store: $selectedLocationId, key: "statistics:selectedLocation" });

export const selectTour = createEvent<number | null>();
export const $selectedTourId = restore(selectTour, null);
persist({ store: $selectedTourId, key: "statistics:selectedTour" });

export const selectOrderDate = createEvent<string | null>();
export const $selectedOrderDateId = restore(selectOrderDate, null);
persist({ store: $selectedOrderDateId, key: "statistics:selectedOrderDate" });

export const selectTourDate = createEvent<string | null>();
export const $selectedTourDateId = restore(selectTourDate, null);
persist({ store: $selectedOrderDateId, key: "statistics:selectedTourDate" });

export const $filteredOrders = combine(
  $allOrders,
  $selectedManagerId,
  $selectedLocationId,
  $selectedTourId,
  $selectedTourDateId,
  $selectedOrderDateId,
  (allOrders, manager, location, tour, tourDate, orderDate) => {
    let filtered = flatten(
      values(groupBy(sortBy(allOrders, "createdAt"), "id"))
    );
    if (manager) {
      let lastOrderId: string;
      let orderOwnerId: number;
      filtered = filtered.filter((order) => {
        const theSameOrder = order.id === lastOrderId;
        if (!theSameOrder) {
          orderOwnerId = order.manager?.id;
        }

        lastOrderId = order.id;
        return order.manager.id === manager || orderOwnerId === manager;
      });
    }

    if (location) {
      filtered = filtered.filter(
        (order) => order.tour.tour.place.id === location
      );
    }

    if (tour) {
      filtered = filtered.filter((order) => order.tour.tour.id === tour);
    }

    if (tourDate) {
      filtered = filtered.filter((order) =>
        order.tour.date.startsWith(tourDate)
      );
    }

    if (orderDate) {
      filtered = filtered.filter((order) =>
        order.createdAt.startsWith(orderDate)
      );
    }

    return filtered;
  }
);

export const $allManagers = combine($filteredOrders, (orders) =>
  sortBy(
    uniqBy(
      orders.map((order) => order.manager),
      "id"
    ),
    "name"
  )
);

export const $allTours = combine($filteredOrders, (orders) =>
  sortBy(
    uniqBy(
      orders.map((order) => order.tour.tour),
      "id"
    ),
    "title"
  )
);

export const $allLocations = combine($filteredOrders, (orders) =>
  sortBy(
    uniqBy(
      orders.map((order) => order.tour.tour.place),
      "id"
    ),
    "name"
  )
);

export const $allTourDates = combine($filteredOrders, (orders) =>
  uniq(orders.map((order) => order.tour.date as string))
    .sort((a, b) => a.localeCompare(b))
    .map((date) => ({ id: date, name: date }))
);

export const $allOrderDates = combine($filteredOrders, (orders) =>
  uniq(orders.map((order) => (order.createdAt as string).substring(0, 10)))
    .sort((a, b) => a.localeCompare(b))
    .map((date) => ({ id: date, name: date }))
);

export const $selectedManager = combine(
  $allManagers,
  $selectedManagerId,
  (managers, selectedManagerId) =>
    managers.find((manager) => manager.id === selectedManagerId) ?? null
);

export const $selectedLocation = combine(
  $allLocations,
  $selectedLocationId,
  (locations, selectedLocationId) =>
    locations.find((location) => location.id === selectedLocationId) ?? null
);

export const $selectedTour = combine(
  $allTours,
  $selectedTourId,
  (tours, selectedTourId) =>
    tours.find((tour) => tour.id === selectedTourId) ?? null
);

export const $selectedTourDate = combine($selectedTourDateId, (date) =>
  date
    ? {
        id: date,
        name: date,
      }
    : null
);

export const $selectedOrderDate = combine($selectedOrderDateId, (date) =>
  date
    ? {
        id: date,
        name: date,
      }
    : null
);

export const $lastRevOrders = combine($filteredOrders, (orders) => {
  const res: Record<string, LoadAllOrdersQuery_booking_all_orders> = {};
  orders.forEach((order) => {
    res[order.id] = order;
    if (order.data === null) {
      delete res[order.id];
    }
  });

  return Object.values(res);
});

$page.watch((page) => {
  if (page !== "statistics") return;
  void fetchAllOrdersFx();
});
