import { Navbar } from "@blueprintjs/core";
import React from "react";

import CustomerSelector from "./CustomerSelector";
import SelectedCustomer from "./SelectedCustomer";
import { $customer } from "_/pages/tours/effector/customer";
import { useStore } from "effector-react";

const CustomerPanel: React.FC = () => {
  const customer = useStore($customer);
  if (customer === null) {
    return (
      <Navbar.Group>
        <CustomerSelector />
      </Navbar.Group>
    );
  }

  return <SelectedCustomer customer={customer} />;
};

export default CustomerPanel;
