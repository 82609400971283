import React, { CSSProperties } from "react";
import { styled } from "linaria/react";
import useOrder from "./useOrder";
import CellWithOrder from "./CellWithOrder";
import CellWithoutOrder from "./CellWithoutOrder";
import CellWithoutCustomer from "./CellWithoutCustomer";
import { useStore } from "effector-react";
import { $customer } from "_/pages/tours/effector/customer";
import { TourPrice } from "_/pages/tours/effector/__generated__/TourPrice";
import { Tours_tours } from "_/pages/tours/effector/__generated__/Tours";

const BareCell: React.FC<{
  className?: string;
  id: number;
  time: string;
  date: string;
  booked: number;
  capacity: number;
  reserve: number;
  prices: TourPrice[];
  style: CSSProperties;
  tour: Tours_tours;
}> = (allProps) => {
  const { booked, date, tour, reserve, ...props } = allProps;
  const { deleteOrder, order, saveOrder } = useOrder(props.id, props.prices);
  const customer = useStore($customer);

  if (customer) {
    return order ? (
      <CellWithOrder
        customer={customer}
        reserve={reserve}
        deleteOrder={deleteOrder}
        {...props}
      />
    ) : (
      <CellWithoutOrder saveOrder={saveOrder} reserve={reserve} {...props} />
    );
  }

  return (
    <CellWithoutCustomer booked={booked} date={date} tour={tour} {...props} />
  );
};

const StyledCell = styled(BareCell)`
  display: block;

  & > .bp3-popover-target {
    display: block;
  }

  --base-bg-color: var(--light-gray5);

  &:nth-child(even) {
    --base-bg-color: var(--light-gray4);
  }
`;

const Cell = React.memo(StyledCell);

export default Cell;
