import { createApi, createStore } from "effector";
import { A, O } from "ts-toolbelt";
import { GetCustomers_customers } from "_/graphql/queries/__generated__/GetCustomers";
import { loadOrdersFx } from "_/pages/tours/effector/orders";
import { persist } from "effector-storage/session";

export interface IAnyCustomer
  extends O.Update<
    O.Nullable<Omit<GetCustomers_customers, "__typename">, "hotel">,
    "id",
    "new" | A.x
  > {}

export const $customer = createStore<IAnyCustomer | null>(null);
persist({ store: $customer, key: "customer" });

$customer.watch((customer) => {
  void loadOrdersFx({ customerId: customer?.id ?? null });
});

export const { set: setCustomer, reset: resetCustomer } = createApi($customer, {
  set: (_, customer: IAnyCustomer) => customer,
  reset: () => null,
});
