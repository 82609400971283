import React from "react";
import { useStore } from "effector-react";

import { DefaultFor } from "_/__generated__/globalTypes";
import { $customer, IAnyCustomer } from "_/pages/tours/effector/customer";
import {
  $orders,
  deleteOrderFx,
  saveOrderFx,
} from "_/pages/tours/effector/orders";
import { TourPrice } from "_/pages/tours/effector/__generated__/TourPrice";

export function getDefault(
  customer: IAnyCustomer | null,
  price: TourPrice
): number {
  if (customer === null) return 0;

  switch (price.defaultFor) {
    case DefaultFor.ADULT:
      return customer.adults;
    case DefaultFor.CHILD:
      return customer.children;
    case DefaultFor.EVERYONE:
      return customer.adults + customer.children + customer.infants;
    case DefaultFor.INFANT:
      return customer.infants;
    case DefaultFor.NO_ONE:
      return 0;
  }
}

const useOrder = (id: number, prices: TourPrice[]) => {
  const customer = useStore($customer)!;
  const orders = useStore($orders);
  const order = orders.find((item) => item.tourId === id);

  const saveOrder = React.useCallback(
    (order?: Parameters<typeof saveOrderFx>[0]) =>
      saveOrderFx(
        order ?? {
          tourId: id,
          data: prices.reduce(
            (acc, price) => ({
              ...acc,
              [price.id]: getDefault(customer, price),
            }),
            {}
          ),
          customerId: customer.id,
          customerRev: customer.rev,
        }
      ),
    [customer, id, prices]
  );

  const deleteOrder = React.useCallback(
    () =>
      deleteOrderFx({
        tourId: id,
        customerId: customer.id,
        customerRev: customer.rev,
      }),
    [customer, id]
  );

  return React.useMemo(
    () => ({
      order,
      saveOrder,
      deleteOrder,
    }),
    [saveOrder, deleteOrder, order]
  );
};

export default useOrder;
