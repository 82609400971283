import { Button } from "@blueprintjs/core";
import React from "react";
import { useStore } from "effector-react";
import { Event, Store } from "effector";

interface ICancelFilterProps<
  TId extends number | string,
  TItem extends { id: TId; name: string }
> {
  valueStore: Store<TItem | null>;
  onChange: Event<TId | null>;
}

const CancelFilter = <
  TId extends number | string,
  TItem extends { id: TId; name: string }
>({
  onChange,
  valueStore,
}: ICancelFilterProps<TId, TItem>) => {
  const value = useStore(valueStore);
  const valueName = typeof value === "string" ? value : value?.name;
  const onChangeHandler = React.useCallback(() => {
    onChange(null);
  }, [onChange]);

  if (!valueName) return null;

  return (
    <Button minimal={true} rightIcon="cross" onClick={onChangeHandler}>
      {valueName}
    </Button>
  );
};

export default CancelFilter;
