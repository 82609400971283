import BaseHeader from "_/components/BaseHeader";
import React from "react";
import LocationSelector from "_/components/Header/LocationSelector";
import { Navbar } from "@blueprintjs/core";
import DatesSelector from "_/components/Header/DatesSelector";
import CustomerPanel from "_/components/CustomerPanel";
import { useStore } from "effector-react";
import { $location } from "_/pages/tours/effector/location";

const ToursHeader: React.FC = () => {
  const locationId = useStore($location);

  const firstLine = (
    <>
      <LocationSelector />
      <Navbar.Divider />
      <DatesSelector />
    </>
  );

  const secondLine = <CustomerPanel />;

  return (
    <BaseHeader
      firstLine={locationId && firstLine}
      secondLine={locationId && secondLine}
    />
  );
};

export default ToursHeader;
