import React from "react";
import { Capacity } from "./styled";

const CellContent: React.FC<{
  time: string;
  capacity: number;
}> = ({ capacity, children, time }) => {
  return (
    <>
      <span>{time}</span>
      <Capacity>{capacity}</Capacity>
      {children}
    </>
  );
};

export default CellContent;
