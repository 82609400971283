import { createApi, createStore } from "effector";

function getDefaultPeriod(): [string, number] {
  const storedDate = window.localStorage.getItem("date");
  const storedLength = window.localStorage.getItem("length");
  const length = storedLength ? parseInt(storedLength, 10) : 10;
  if (storedDate) {
    return [storedDate, length];
  }

  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  if (month >= 6) {
    return [`${year}-12-22`, length];
  }

  return [`${year - 1}-12-22`, length];
}

export const $period = createStore(getDefaultPeriod());

export const { setDate, setDays } = createApi($period, {
  setDate: (store, date: string) => [date, store[1]],
  setDays: (store, days: number) => [store[0], days],
});
