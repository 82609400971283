import React from "react";
import LocationDialog from "_/components/LocationDialog";
import Tours from "_/components/Tours";
import { useStore } from "effector-react";
import { $location } from "_/pages/tours/effector/location";
import View from "_/components/View";
import ToursHeader from "_/pages/tours/ToursHeader";

const ToursPage: React.FC = () => {
  const locationId = useStore($location);

  return (
    <View navbar={<ToursHeader />}>
      <LocationDialog isOpen={locationId === null} />
      <Tours />
    </View>
  );
};

export default ToursPage;
