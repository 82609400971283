import React from "react";
import { useStore } from "effector-react";
import { $lastRevOrders } from "_/effector/statistics";
import { format } from "date-fns";
import { getTourTitle } from "_/components/Statistics/utils";
import { filterPrices } from "_/components/Statistics";
import { orderBy, zipObject } from "lodash";
import { Button } from "@blueprintjs/core";
import sanitizeFileName from "sanitize-filename";
import { $filterName } from "./filters";

function fitToColumn(arrayOfArray: (string | number)[][]) {
  return arrayOfArray[0].map((a, i) => ({
    wch: Math.max(
      ...arrayOfArray.map((a2) => (a2[i] ? a2[i].toString().length : 0))
    ),
  }));
}

const headers = [
  "Order date",
  "Customer",
  "Excursion",
  "Excursion date",
  "Manager",
  "Cost",
  "Delta",
  "Details",
];

const Download: React.FC = () => {
  const fileName = sanitizeFileName(useStore($filterName));
  const lastRevOrders = useStore($lastRevOrders);

  const download = React.useCallback(async () => {
    const arrayOfArray = lastRevOrders.map((order): (string | number)[] => [
      format(new Date(order.createdAt), "yyyy-MM-dd HH:mm:ss"),
      order.customer.name,
      getTourTitle(order.tour.tour),
      `${order.tour.date} ${order.tour.time}`,
      order.manager.name,
      parseInt(order.cost),
      parseInt(order.delta),
      filterPrices(order.data, order.tour.prices)
        .map(({ id, slug }) => `${slug}: ${order.data[id]}`)
        .join(" / "),
    ]);

    const json = orderBy(
      arrayOfArray.map((row) => zipObject(headers, row)),
      "Excursion date"
    );
    const XLSX = await import("xlsx");
    const workBook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(json, { header: headers });
    worksheet["!cols"] = fitToColumn(arrayOfArray);
    XLSX.utils.book_append_sheet(workBook, worksheet, "Orders");
    XLSX.writeFile(workBook, `${fileName}.xlsx`);
  }, [fileName, lastRevOrders]);

  return <Button onClick={download}>Download</Button>;
};

export default Download;
