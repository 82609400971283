import React, { CSSProperties } from "react";
import useOrder from "./useOrder";
import { cx } from "linaria";
import { Popover, Switch } from "@blueprintjs/core";
import OrderEditor from "./OrderEditor";
import { customerClass, selectedClass, Target, warningClass } from "./styled";
import CellContent from "./CellContent";
import useCellHandlers from "./useCellHandlers";
import { IAnyCustomer } from "_/pages/tours/effector/customer";
import { TourPrice } from "_/pages/tours/effector/__generated__/TourPrice";

const CellWithOrder: React.FC<{
  capacity: number;
  reserve: number;
  className?: string;
  customer: IAnyCustomer;
  deleteOrder: () => void;
  id: number;
  style: CSSProperties;
  prices: TourPrice[];
  time: string;
}> = React.memo(
  ({
    capacity,
    className,
    customer,
    deleteOrder,
    id,
    prices,
    reserve,
    style,
    time,
  }) => {
    const { order } = useOrder(id, prices);
    const total =
      (customer?.adults ?? 0) +
      (customer?.children ?? 0) +
      (customer?.infants ?? 0);
    const booked = Object.values(order?.data ?? {}).reduce(
      (acc, value) => acc + value,
      0
    );

    const targetClassName = cx(
      "tours-table_time-slot",
      selectedClass,
      booked > total && warningClass,
      customerClass
    );

    const fraction = total ? booked / total : 0;
    const allStyles = React.useMemo(
      () => ({ ...style, "--fraction": fraction }),
      [style, fraction]
    );

    const handlers = useCellHandlers(deleteOrder);

    return (
      <Popover openOnTargetFocus className={className}>
        <Target
          style={allStyles}
          className={targetClassName}
          role="checkbox"
          aria-checked="false"
          tabIndex={0}
          onKeyDown={handlers.onKeyDown}
        >
          <CellContent capacity={capacity} time={time}>
            <Switch
              tabIndex={-1}
              inline={true}
              checked={true}
              onChange={handlers.onChange}
            />
          </CellContent>
        </Target>
        <OrderEditor
          slotId={id}
          prices={prices}
          max={capacity + reserve + booked}
        />
      </Popover>
    );
  }
);

export default CellWithOrder;
