import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IconDefinition } from "@fortawesome/fontawesome-common-types";

const FaIcon: React.FC<{ icon: IconDefinition }> = ({ icon }) => (
  <span className="bp3-icon">
    <FontAwesomeIcon icon={icon} />
  </span>
);

export default FaIcon;
