import React from "react";
import isHotkey from "is-hotkey";

const isEnter = isHotkey(["enter", "space"]);
const isDirectionKey = isHotkey(["up", "down", "left", "right"]);

const useCellHandlers = (action: () => void) => {
  const onKeyDown = React.useCallback(
    (ev: React.KeyboardEvent) => {
      if (isEnter(ev.nativeEvent)) {
        ev.preventDefault();
        action();
      }

      if (isDirectionKey(ev.nativeEvent)) {
        ev.preventDefault();
      }
    },
    [action]
  );

  const onChange = React.useCallback(() => action(), [action]);

  return React.useMemo(
    () => ({
      onKeyDown,
      onChange,
    }),
    [onKeyDown, onChange]
  );
};

export default useCellHandlers;
