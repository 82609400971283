import { HTMLSelect } from "@blueprintjs/core";
import React from "react";
import { useStore } from "effector-react";
import { Event, Store } from "effector";

interface IAddFilterProps<
  TId extends number | string,
  TItem extends { id: TId; name: string }
> {
  defaultOption: { id: TId; name: string };
  valueStore: Store<TItem | null>;
  optionsStore: Store<TItem[]>;
  onChange: Event<TId | null>;
}

const AddFilter = <
  TId extends number | string,
  TItem extends { id: TId; name: string }
>({
  defaultOption,
  optionsStore,
  onChange,
  valueStore,
}: IAddFilterProps<TId, TItem>) => {
  const valueItem = useStore(valueStore);
  const value = typeof valueItem === "string" ? valueItem : valueItem?.id;
  const options = useStore(optionsStore);
  const onChangeHandler = React.useCallback(
    (ev: React.ChangeEvent<HTMLSelectElement>) => {
      if (typeof defaultOption.id === "string") {
        onChange((ev.target.value as TId) || null);
      } else {
        const id = ev.target.value && parseInt(ev.target.value, 10);
        onChange((id as TId) || null);
      }
    },
    [defaultOption.id, onChange]
  );

  if (options.length <= 1) return null;

  return (
    <HTMLSelect minimal={true} value={value || 0} onChange={onChangeHandler}>
      <option value={defaultOption.id}>{defaultOption.name}</option>
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </HTMLSelect>
  );
};

export default AddFilter;
