import { gql, useQuery } from "@apollo/client";
import { QueryHookOptions } from "@apollo/client/react/types/types";
import { GetHotels, GetHotelsVariables } from "./__generated__/GetHotels";

export const getHotels = gql`
  query GetHotels($location: Int!) {
    hotels: booking_hotels(location: $location) {
      id
      name
    }
  }
`;

export default function useLocations(
  options?: QueryHookOptions<GetHotels, GetHotelsVariables>
) {
  return useQuery<GetHotels, GetHotelsVariables>(getHotels, options);
}
