import { Button, Classes, Icon, InputGroup, Spinner } from "@blueprintjs/core";
import Confirmation from "_/components/CustomerPanel/SelectedCustomer/Voucher/Confimation";
import React from "react";
import client from "_/graphql/client";
import {
  SaveVoucher,
  SaveVoucherVariables,
} from "_/components/CustomerPanel/SelectedCustomer/Voucher/__generated__/SaveVoucher";
import { pick } from "lodash";
import { styled } from "linaria/react";
import { gql, useQuery } from "@apollo/client";
import { IOrder, ORDER_REF_FRAGMENT } from "_/pages/tours/effector/orders";
import { IAnyCustomer } from "_/pages/tours/effector/customer";
import {
  GetPreVoucher,
  GetPreVoucherVariables,
} from "_/components/CustomerPanel/SelectedCustomer/Voucher/__generated__/GetPreVoucher";

const VOUCHER_FRAGMENT = gql`
  ${ORDER_REF_FRAGMENT}
  fragment Voucher on TourVoucher {
    customerId
    rev
    customerRev
    cost
    data {
      orders {
        ...OrderRef
      }
      phone
    }
  }
`;

const GET_PRE_VOUCHER = gql`
  query GetPreVoucher($customerId: ID!) {
    customer: booking_customer(customerId: $customerId) {
      name
      hotel {
        name
      }
      orders {
        id
        rev
        cost
        data
        slot: tour {
          date
          time
          tour {
            title
          }
          prices {
            id
            name
            value
          }
        }
      }
      voucher {
        data {
          phone
          prices
        }
      }
    }
  }
`;

const SAVE_VOUCHER = gql`
  ${VOUCHER_FRAGMENT}
  mutation SaveVoucher(
    $customerId: ID!
    $customerRev: Int!
    $orders: [TourOrderRefInput!]!
    $phone: String
    $customPrices: JSON
  ) {
    saveVoucher(
      customerId: $customerId
      customerRev: $customerRev
      orders: $orders
      phone: $phone
      customPrices: $customPrices
    ) {
      ...Voucher
    }
  }
`;

const getUrl = (id: string) => {
  const host = window.location.host.startsWith("localhost:")
    ? "localhost:3000"
    : "easytravel.eu";
  return `${window.location.protocol}//${host}/v/et/${id}?print`;
};

const PhoneField = styled.div`
  margin-top: var(--pt-grid-size);
  display: flex;
  align-items: center;

  & > span {
    margin-right: var(--pt-grid-size);
  }

  & > div {
    flex-grow: 1;
  }
`;

const getStoredValues = (ids: Set<string | number>, rev: number) => {
  const result: Record<string, number | undefined> = {};
  ids.forEach((id) => {
    const value = localStorage.getItem(`custom-price:${id}`) || "0";
    result[id] = value ? parseInt(value, 10) : 0;
  });

  return result;
};

const VoucherDialog: React.FC<{
  closeModal: () => void;
  customer: IAnyCustomer;
  orders: IOrder[];
}> = ({ closeModal, customer, orders }) => {
  const { loading, data: preVoucherData } = useQuery<
    GetPreVoucher,
    GetPreVoucherVariables
  >(GET_PRE_VOUCHER, {
    fetchPolicy: "network-only",
    variables: {
      customerId: customer.id,
    },
  });

  const [rev, setRev] = React.useState(0);
  const priceIds = React.useMemo(
    () => new Set(orders.flatMap((item) => Object.keys(item.data ?? {}))),
    [orders]
  );
  const customPrices = React.useMemo(
    () => getStoredValues(priceIds, rev),
    [rev, priceIds]
  );
  const onCustomChange = React.useCallback((id: number, value: number) => {
    setRev((i) => i + 1);
    localStorage.setItem(`custom-price:${id}`, value.toString());
  }, []);

  const getCustom = (orderData: Record<string, number>) => {
    return Object.keys(orderData).reduce((acc, priceId) => {
      return acc + (customPrices[priceId] ?? 0) * orderData[priceId];
    }, 0);
  };

  const additionalSum = orders.reduce(
    (acc, order) => acc + getCustom(order.data ?? {}),
    0
  );

  const sum = orders.reduce((acc, order) => acc + parseFloat(order.cost), 0);

  const savedPhone = preVoucherData?.customer.voucher?.data.phone ?? "";
  const [phone, setPhone] = React.useState(savedPhone);
  const onPhoneChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >((ev) => {
    setPhone(ev.target.value);
  }, []);

  const book = React.useCallback(async () => {
    if (customer == null) return;
    const result = await client.mutate<SaveVoucher, SaveVoucherVariables>({
      mutation: SAVE_VOUCHER,
      variables: {
        customerId: customer.id,
        customerRev: customer.rev,
        orders: orders.map((order) => pick(order, ["id", "rev"])),
        phone,
        customPrices,
      },
    });

    const saved = result.data?.saveVoucher!;
    window.open(getUrl(saved.customerId));
    closeModal();
  }, [closeModal, customPrices, customer, orders, phone]);

  React.useEffect(() => {
    setPhone(savedPhone);
  }, [savedPhone])

  if (loading || !preVoucherData) return <Spinner />;

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <Confirmation
          customer={preVoucherData.customer}
          customPrices={customPrices}
          onCustomChange={onCustomChange}
        />
        <PhoneField>
          <Icon icon={"phone"} />
          <InputGroup
            placeholder="Optional phone number"
            onChange={onPhoneChange}
            value={phone}
          />
        </PhoneField>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={closeModal}>Close</Button>
          <Button intent={"success"} onClick={book}>
            Voucher €{sum + additionalSum}
          </Button>
        </div>
      </div>
    </>
  );
};

export default VoucherDialog;
