import { css } from "linaria";
import { styled } from "linaria/react";

export const Capacity = styled.span``;

export const Target = styled.div`
  && .bp3-control {
    display: none;
    margin: 0;
  }

  background: var(--base-bg-color);
`;

export const customerClass = css`
  &:hover,
  &:focus {
    padding-right: 0;

    & > ${Capacity} {
      display: none;
    }

    & > .bp3-control {
      display: block;
    }
  }
`;

export const selectedClass = css`
  background: repeating-linear-gradient(
    -45deg,
    var(--base-bg-color),
    var(--base-bg-color) calc(20px * (1 - var(--fraction))),
    var(--gold5) calc(20px * (1 - var(--fraction))),
    var(--gold5) 20px
  );
`;

export const warningClass = css`
  background: var(--red5);
`;
