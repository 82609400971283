import { HTMLTable } from "@blueprintjs/core";
import React from "react";
import { keyBy } from "lodash";
import { styled } from "linaria/react";
import { GetPreVoucher_customer_orders } from "./__generated__/GetPreVoucher";

const TourTitle = styled.h3`
  & > div > small {
    display: block;
  }

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: var(--pt-grid-size);
`;

const Table = styled(HTMLTable)`
  width: 100%;
  td ~ td {
    width: 80px;
    text-align: right !important;
  }
`;

const CustomPriceInput = styled.input`
  background: transparent;
  border: none;
  border-bottom: 1px dotted #777;
  width: 30px;
  text-align: center;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const FormulaColumn = styled.td`
  width: 150px;
  white-space: nowrap;
`;

const Price: React.FC<{
  onCustomChange: (id: number, value: number) => void;
  price: {
    id: number;
    name: string;
    count: number;
    value: number;
    total: number;
  };
  value: number;
}> = ({ onCustomChange, price, value }) => {
  const onChangeHandler = React.useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = ev.target.valueAsNumber || 0;
      onCustomChange(price.id, newValue);
    },
    [onCustomChange, price.id]
  );
  return (
    <tr>
      <td>{price.name}</td>
      <FormulaColumn>
        {price.count}×€({price.value} +{" "}
        <CustomPriceInput
          value={value}
          type="number"
          min={0}
          onChange={onChangeHandler}
        />
        )
      </FormulaColumn>
      <td>€{price.total}</td>
    </tr>
  );
};

const OrderInfo: React.FC<{
  customPrices: Record<string, number | undefined>;
  onCustomChange: (id: number, value: number) => void;
  order: GetPreVoucher_customer_orders;
}> = React.memo(({ customPrices, onCustomChange, order }) => {
  const priceInfo = keyBy(order.slot.prices, "id");
  const prices = Object.entries(order.data as Record<string, number>).map(
    ([priceId, count]) => ({
      ...priceInfo[priceId],
      count,
      total: (priceInfo[priceId].value + (customPrices[priceId] ?? 0)) * count,
    })
  );
  const total = prices.reduce((acc, row) => acc + row.total, 0);
  return (
    <div>
      <TourTitle>
        <div>
          <small>
            {order.slot.date} {order.slot.time.substr(0, 5)}
          </small>
          {order.slot.tour.title}
        </div>
        <span>€{total}</span>
      </TourTitle>
      <Table condensed={true} striped={true} bordered={true}>
        <tbody>
          {prices
            .filter((price) => price.count > 0)
            .map((price) => (
              <Price
                value={customPrices[price.id] ?? 0}
                key={price.id}
                onCustomChange={onCustomChange}
                price={price}
              />
            ))}
        </tbody>
      </Table>
    </div>
  );
});

export default OrderInfo;
