import {
  Button,
  ControlGroup,
  HTMLSelect,
  NumericInput,
} from "@blueprintjs/core";
import { faBaby, faChild, faMale } from "@fortawesome/free-solid-svg-icons";
import { isEqual } from "lodash";
import React from "react";

import FaIcon from "../../FaIcon";
import { showToast } from "../../Toaster";
import useAddCustomer from "../../../graphql/queries/useAddCustomer";
import useUpdateCustomer from "../../../graphql/queries/useUpdateCustomer";
import useHotels from "../../../graphql/queries/useHotels";
import { useStore } from "effector-react";
import { $location } from "_/pages/tours/effector/location";
import { IAnyCustomer, setCustomer } from "_/pages/tours/effector/customer";

const CustomerEditor: React.FC<{
  className?: string;
  customer: IAnyCustomer;
}> = ({ className, customer }) => {
  const locationId = useStore($location)!;

  const onRender = React.useCallback(
    (ref: HTMLSelectElement | null) => {
      if (ref !== null && customer.hotel === null) {
        ref.focus();
      }
    },
    [customer.hotel]
  );

  const initialValues = React.useMemo(
    () => ({
      hotelId: customer.hotel?.id ?? null,
      adults: customer.adults,
      children: customer.children,
      infants: customer.infants,
    }),
    [customer]
  );

  type Values = typeof initialValues;

  const [formState, localDispatch] = React.useReducer(
    (
      prevState: Values,
      action: { action: keyof Values; value: number | null } | "reset"
    ) => {
      if (action === "reset") {
        return {
          ...initialValues,
        };
      }

      const { action: field, value } = action;
      return prevState[field] === value
        ? prevState
        : {
            ...prevState,
            [field]: value,
          };
    },
    initialValues
  );

  const hotelsResponse = useHotels({
    variables: {
      location: locationId,
    },
  });

  const hotels = React.useMemo(
    () => hotelsResponse?.data?.hotels ?? [],
    [hotelsResponse]
  );

  const canBeSaved = React.useMemo(
    () => !isEqual(initialValues, formState) && formState.hotelId !== null,
    [initialValues, formState]
  );

  const [addCustomer] = useAddCustomer();
  const [updateCustomer] = useUpdateCustomer();

  const saveCustomer = React.useCallback(
    async (ev: React.FormEvent) => {
      ev.preventDefault();
      if (!canBeSaved) return;
      let id = customer.id;
      let rev: number;
      if (id === "new") {
        const { data } = await addCustomer({
          variables: {
            name: customer.name,
            ...formState,
            hotelId: formState.hotelId!,
          },
        });
        id = data?.addCustomer?.id!;
        rev = data?.addCustomer?.rev!;
      } else {
        const { data } = await updateCustomer({
          variables: {
            id: customer.id,
            name: customer.name,
            ...formState,
            hotelId: formState.hotelId!,
          },
        });
        rev = data?.updateCustomer?.rev!;
      }

      showToast({
        icon: "thumbs-up",
        intent: "success",
        message: `"${customer.name}" has been successfully saved.`,
      });

      setCustomer({
        id,
        rev,
        hotel: hotels.find((item) => item.id === formState.hotelId)!,
        name: customer.name,
        adults: formState.adults,
        children: formState.children,
        infants: formState.infants,
      });
    },
    [
      addCustomer,
      canBeSaved,
      customer.id,
      customer.name,
      formState,
      hotels,
      updateCustomer,
    ]
  );

  return (
    <form onSubmit={saveCustomer}>
      <ControlGroup fill={true} vertical={false} className={className}>
        <HTMLSelect
          value={formState.hotelId ?? 0}
          elementRef={onRender}
          onChange={(event) =>
            localDispatch({
              action: "hotelId",
              value: parseInt(event.target.value, 10) || null,
            })
          }
        >
          <option value={0}>Choose hotel…</option>
          {hotels.map((hotel) => (
            <option key={hotel.id} value={hotel.id}>
              {hotel.name}
            </option>
          ))}
        </HTMLSelect>
        <NumericInput
          buttonPosition={"none"}
          minorStepSize={null}
          min={0}
          max={99}
          size={2}
          leftIcon={<FaIcon icon={faMale} />}
          clampValueOnBlur={true}
          defaultValue={initialValues.adults}
          onValueChange={(value) => localDispatch({ action: "adults", value })}
        />
        <NumericInput
          buttonPosition={"none"}
          minorStepSize={null}
          min={0}
          max={99}
          size={2}
          leftIcon={<FaIcon icon={faChild} />}
          clampValueOnBlur={true}
          defaultValue={initialValues.children}
          onValueChange={(value) =>
            localDispatch({ action: "children", value })
          }
        />
        <NumericInput
          buttonPosition={"none"}
          minorStepSize={null}
          min={0}
          max={99}
          size={2}
          leftIcon={<FaIcon icon={faBaby} />}
          clampValueOnBlur={true}
          defaultValue={initialValues.infants}
          onValueChange={(value) => localDispatch({ action: "infants", value })}
        />
        <Button type="submit" disabled={!canBeSaved} intent="primary">
          Save
        </Button>
      </ControlGroup>
    </form>
  );
};

export default CustomerEditor;
