import React from "react";
import { styled } from "linaria/react";
import { IAnyCustomer } from "_/pages/tours/effector/customer";

const Id = styled.div`
  font-family: monospace;
`;

const CustomerWrapper = styled.div`
  & > div {
    display: flex;
    align-items: baseline;

    &:last-of-type {
      font-size: 80%;
    }

    & > div:first-of-type {
      flex-grow: 1;
      flex-basis: 100px;
    }

    & > div ~ div {
      margin-left: 1em;
      flex-grow: 0;
      flex-basis: 50px;
      text-align: right;
    }
  }
`;

const Customer: React.FC<{
  customer: IAnyCustomer & { manager?: { name: string } };
}> = ({ customer }) => {
  const customerId = customer.id ?? "new";
  const [hotel, pickupPoint] = customer.hotel?.name
    .split("/")
    .map((t) => t.trim())!;

  return (
    <CustomerWrapper>
      <div>
        <div>{customer.name}</div>
        {customer.manager ? (
          <div>{customer.manager.name}</div>
        ) : (
          <Id title={customerId}>{customerId.split("-")[0]}</Id>
        )}
      </div>
      <div>
        <div>{hotel}</div>
        {pickupPoint && <div>{pickupPoint.replace("pick-up", "").trim()}</div>}
      </div>
    </CustomerWrapper>
  );
};

export default React.memo(Customer);
