import { gql, useQuery } from "@apollo/client";
import {
  GetCustomers,
  GetCustomersVariables,
} from "./__generated__/GetCustomers";
import { QueryHookOptions } from "@apollo/client/react/types/types";

export const CUSTOMER_FRAGMENT = gql`
  fragment Customer on TourCustomer {
    adults
    children
    hotel {
      id
      name
    }
    id
    infants
    name
    rev
  }
`;

const getCustomers = gql`
  ${CUSTOMER_FRAGMENT}
  query GetCustomers($locationId: Int!, $filter: String) {
    customers: booking_customers(location: $locationId, filter: $filter) {
      ...Customer
    }
  }
`;

export default function useCustomers(
  options?: QueryHookOptions<GetCustomers, GetCustomersVariables>
) {
  return useQuery<GetCustomers, GetCustomersVariables>(getCustomers, options);
}
