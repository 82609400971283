import {
  Button,
  Classes,
  Dialog,
  Intent,
  FormGroup,
  InputGroup,
} from "@blueprintjs/core";
import { styled } from "linaria/react";
import * as React from "react";
import { FormikConfig, useFormik } from "formik";
import * as yup from "yup";

import logo from "./logo.svg";
import { loginFx } from "_/effector/auth";

const state = {
  autoFocus: true,
  canEscapeKeyClose: false,
  canOutsideClickClose: false,
  enforceFocus: true,
  isCloseButtonShown: false,
  isOpen: true,
  usePortal: false,
};

const validationSchema = yup
  .object()
  .shape({
    company: yup.string(),
    login: yup.string().required(),
    password: yup.string().required(),
  })
  .required();

type Values = yup.InferType<typeof validationSchema>;

const initialValues: Values = {
  company: "",
  login: "",
  password: "",
};

const Logo = styled.img`
  display: block;
  margin: 1em auto;
  width: 50%;
`;

const AuthDialog: React.FC = () => {
  const onSubmit = React.useCallback<FormikConfig<Values>["onSubmit"]>(
    (values, { setSubmitting }) => {
      void loginFx(values)
        .catch(() => {})
        .finally(() => setSubmitting(false));
    },
    []
  );

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Dialog title={<Logo src={logo} alt="EasyTravel" />} {...state}>
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label="Company" labelFor="company">
            <InputGroup
              id="company"
              placeholder="EasyTravel"
              autoComplete="company"
              onChange={formik.handleChange}
              value={formik.values.company}
            />
          </FormGroup>
          <FormGroup label="Login" labelFor="login">
            <InputGroup
              id="login"
              autoComplete="username"
              onChange={formik.handleChange}
              value={formik.values.login}
            />
          </FormGroup>
          <FormGroup label="Password" labelFor="password">
            <InputGroup
              id="password"
              type="password"
              autoComplete="current-password"
              onChange={formik.handleChange}
              value={formik.values.password}
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button intent={Intent.PRIMARY} type="submit">
              Sign in
            </Button>
          </div>
        </div>
      </Dialog>
    </form>
  );
};

export default AuthDialog;
