import { Card, FormGroup, NumericInput } from "@blueprintjs/core";
import { css } from "linaria";
import { FormikConfig, useFormik } from "formik";
import React from "react";

import useOrder, { getDefault } from "./useOrder";
import { styled } from "linaria/react";
import { useStore } from "effector-react";
import { $customer } from "_/pages/tours/effector/customer";
import { TourPrice } from "_/pages/tours/effector/__generated__/TourPrice";

const formClass = css`
  user-select: none;
`;

const Label = styled.div`
  margin: 0;
  display: flex;
  justify-content: space-between;

  & > strong {
    margin-left: 1em;
  }
`;

const UnstyledTotal: React.FC<{
  className?: string;
  prices: TourPrice[];
  values: Record<number, number>;
}> = ({ className, prices, values }) => {
  const total = prices.reduce(
    (acc, price) => acc + (price.value ?? 0) * (values[price.id] ?? 0),
    0
  );
  return <div className={className}>€{total}</div>;
};

const Total = styled(UnstyledTotal)`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
`;

const OrderEditor: React.FC<{
  prices: TourPrice[];
  slotId: number;
  max: number;
}> = ({ max, prices, slotId }) => {
  const customer = useStore($customer)!;
  const { order, saveOrder } = useOrder(slotId, prices);

  const initialValues = React.useMemo(
    () =>
      order?.data ??
      prices.reduce(
        (acc, price) => ({ ...acc, [price.id]: getDefault(customer, price) }),
        {}
      ),
    [customer, order, prices]
  );

  type Values = typeof initialValues;

  const onSubmit = React.useCallback<FormikConfig<Values>["onSubmit"]>(
    async (values) => {
      if (initialValues === values) {
        return;
      }

      return saveOrder({
        tourId: slotId,
        data: values,
        customerId: customer.id,
        customerRev: customer.rev,
      });
    },
    [customer, initialValues, saveOrder, slotId]
  );

  const { handleSubmit, values, setFieldValue, setFieldTouched } = useFormik({
    initialValues,
    onSubmit,
  });

  React.useEffect(() => {
    return handleSubmit;
  }, [handleSubmit]);

  const handlers = React.useMemo(() => {
    const keys = Object.keys(initialValues);
    return keys.reduce(
      (acc, field) => ({
        ...acc,
        [field]: (value: number) => {
          setFieldValue(field, value);
          setFieldTouched(field, true);
        },
      }),
      {}
    ) as Record<keyof Values, (value: number) => void>;
  }, [initialValues, setFieldValue, setFieldTouched]);

  return (
    <Card>
      <form className={formClass}>
        {prices.map((price) => (
          <FormGroup
            key={price.id}
            label={
              <Label>
                {price.name}
                <strong>€{price.value}</strong>
              </Label>
            }
          >
            <NumericInput
              fill={true}
              min={0}
              name={price.id.toString()}
              onValueChange={handlers[price.id]}
              defaultValue={values[price.id] ?? 0}
            />
          </FormGroup>
        ))}
        <Total prices={prices} values={values} />
      </form>
    </Card>
  );
};

export default OrderEditor;
