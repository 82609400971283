/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DefaultFor {
  ADULT = "ADULT",
  CHILD = "CHILD",
  EVERYONE = "EVERYONE",
  INFANT = "INFANT",
  NO_ONE = "NO_ONE",
}

export interface TourOrderRefInput {
  id: string;
  rev: number;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
