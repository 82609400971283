import { HTMLTable } from "@blueprintjs/core";
import React from "react";
import { css, cx } from "linaria";
import { useStore } from "effector-react";
import { format } from "date-fns";
import { $filteredOrders } from "_/effector/statistics";
import {
  LoadAllOrdersQuery_booking_all_orders,
  LoadAllOrdersQuery_booking_all_orders_tour_prices,
} from "_/effector/__generated__/LoadAllOrdersQuery";
import { groupBy, sortBy } from "lodash";
import { setLocation } from "_/pages/tours/effector/location";
import { setCustomer } from "_/pages/tours/effector/customer";
import { changePage } from "_/effector/router";
import { getTourTitle } from "_/components/Statistics/utils";

const cancelledOrder = css``;

const tableClass = css`
  width: 100%;

  tbody {
    cursor: pointer;
    --color: #999;

    &:hover {
      tr {
        background-color: #bfccd64d;
      }
    }

    &:nth-child(odd) {
      background-color: rgba(191, 204, 214, 0.15);
    }

    & > tr {
      &:first-child > td {
        padding-top: 10px;
      }

      &:last-child > td {
        padding-bottom: 10px;
        color: inherit;
      }

      & > td {
        color: var(--color);
        padding: 0 10px 5px;
      }

      &.${cancelledOrder} > td {
        color: var(--color);
      }
    }
  }

  tfoot > tr > td {
    text-align: center;
  }
`;

const price = css`
  & + &:before {
    content: " / ";
  }
`;

export const filterPrices = (
  data: Record<number, number>,
  prices: LoadAllOrdersQuery_booking_all_orders_tour_prices[]
) => sortBy(prices, "id").filter(({ id }) => data[id]);

const Prices: React.FC<{
  data: Record<number, number>;
  prices: LoadAllOrdersQuery_booking_all_orders_tour_prices[];
}> = ({ data, prices }) => {
  return (
    <>
      {filterPrices(data, prices).map(({ id, slug }) => (
        <span className={price} key={id}>
          {slug}: {data[id]}
        </span>
      ))}
    </>
  );
};

const Order: React.FC<{
  className?: string;
  order: LoadAllOrdersQuery_booking_all_orders;
}> = ({ className, order }) => {
  const openInTours = React.useCallback(() => {
    setLocation(order.tour.tour.place.id);
    setCustomer(order.customer);
    changePage("tours");
  }, [order.customer, order.tour.tour.place.id]);

  return (
    <tr
      key={`${order.id}/${order.rev}`}
      className={cx(className)}
      onClick={openInTours}
    >
      <td>{format(new Date(order.createdAt), "yyyy-MM-dd HH:mm:ss")}</td>
      <td>{order.customer.name}</td>
      <td>{getTourTitle(order.tour.tour)}</td>
      <td>
        {order.tour.date} {order.tour.time}
      </td>
      <td>{order.manager.name}</td>
      <td>{parseInt(order.cost)}</td>
      <td>{parseInt(order.delta)}</td>
      <td>
        {order.data === null ? (
          "Cancelled"
        ) : (
          <Prices data={order.data} prices={order.tour.prices} />
        )}
      </td>
    </tr>
  );
};

const Statistics: React.FC = () => {
  const orders = useStore($filteredOrders);
  const groupedOrders = React.useMemo(() => groupBy(orders, "id"), [orders]);
  return (
    <HTMLTable className={tableClass} width="100%">
      {Object.keys(groupedOrders).map((orderId) => (
        <tbody key={orderId}>
          {groupedOrders[orderId].map((order) => (
            <Order
              key={`${order.id}/${order.rev}`}
              order={order}
              className={cx(order.data === null && cancelledOrder)}
            />
          ))}
        </tbody>
      ))}
    </HTMLTable>
  );
};

export default Statistics;
