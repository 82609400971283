import {
  combine,
  createEffect,
  createEvent,
  createStore,
  restore,
} from "effector";
import client from "_/graphql/client";
import { gql } from "@apollo/client";
import { $location } from "_/pages/tours/effector/location";
import { $period } from "_/pages/tours/effector/period";
import {
  Tours,
  ToursVariables,
} from "_/pages/tours/effector/__generated__/Tours";

export const SLOT_FRAGMENT = gql`
  fragment TourSlot on TourSlot {
    id
    booked
    capacity
    reserve
    date
    time
  }
`;

export const TOUR_PRICE_FRAGMENT = gql`
  fragment TourPrice on TourPrice {
    id
    name
    slug
    value
    defaultFor
  }
`;

const ToursQuery = gql`
  ${SLOT_FRAGMENT}
  ${TOUR_PRICE_FRAGMENT}
  query Tours($locationId: Int!, $date: Date!, $days: Int!) {
    tours: booking_tours(location: $locationId) {
      id
      title
      slots(date: $date, days: $days) {
        ...TourSlot
      }
      prices {
        ...TourPrice
      }
    }
  }
`;

export const reloadTours = createEvent();

const $key = createStore(0).on(reloadTours, (i) => i + 1);

export const fetchToursFx = createEffect(
  async ({
    location,
    date,
    days,
    key,
  }: {
    location: number | null;
    date: string;
    days: number;
    key: number;
  }) => {
    if (location === null) return [];

    const result = await client.query<Tours, ToursVariables>({
      query: ToursQuery,
      variables: {
        locationId: location,
        date,
        days,
      },
      fetchPolicy: "network-only",
    });

    return result.data?.tours ?? [];
  }
);

export const $tours = restore(fetchToursFx, []);

combine($location, $period, $key, (location, [date, days], key) => ({
  location,
  date,
  days,
  key,
})).watch((state) => fetchToursFx(state));
