import React, { CSSProperties } from "react";
import { css, cx } from "linaria";
import { Target } from "./styled";
import CellContent from "./CellContent";
import useCellHandlers from "./useCellHandlers";
import { Classes, Drawer, Button } from "@blueprintjs/core";
import { styled } from "linaria/react";
import ListOfParticipant from "./ListOfParticipant";
import { Tours_tours } from "_/pages/tours/effector/__generated__/Tours";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDF from "_/components/Tours/ListOfParticipant/PDF";
import sanitizeFileName from "sanitize-filename";
import { useListOfParticipant } from "_/components/Tours/ListOfParticipant/useListOfParticipant";

const dialogOptions = {
  autoFocus: true,
  canEscapeKeyClose: true,
  canOutsideClickClose: true,
  enforceFocus: true,
  usePortal: true,
};

const FixedSizeDrawer = styled(Drawer)`
  width: min(100%, 600px) !important;

  @media print {
    :global(#root, .bp3-dialog-close-button, .bp3-overlay-backdrop) {
      display: none !important;
    }

    :global(.bp3-overlay-open, .bp3-portal, .bp3-overlay-content, .bp3-overlay-container) {
      overflow: auto !important;
      position: relative !important;
    }

    & {
      width: 100% !important;
    }
  }
`;

const empty = css`
  opacity: 0.5;
  cursor: not-allowed;
`;

const UnstyledTitle: React.FC<{
  className?: string;
  children: string;
  id: number;
}> = ({ className, children, id }) => {
  const { isLoading, checkpoints, summary, data } = useListOfParticipant(id);

  const fileName = sanitizeFileName(children);
  return (
    <div className={className}>
      <div>{children}</div>
      {!isLoading && data ? (
        <PDFDownloadLink
          document={
            <PDF
              tour={data.tour}
              title={children}
              summary={summary}
              checkpoints={checkpoints}
            />
          }
          fileName={fileName}
        >
          <Button icon="download" />
        </PDFDownloadLink>
      ) : null}
      <Button icon="print" onClick={() => window.print()} />
    </div>
  );
};

const Title = styled(UnstyledTitle)`
  display: flex;
  align-items: center;

  & > div {
    margin-right: auto;
  }

  & > a,
  & > button {
    margin-left: 10px;
  }

  @media print {
    button,
    a {
      display: none;
    }
  }
`;

const CellWithoutCustomer: React.FC<{
  booked: number;
  capacity: number;
  className?: string;
  id: number;
  style: CSSProperties;
  date: string;
  time: string;
  tour: Tours_tours;
}> = React.memo(
  ({ booked, capacity, className, date, id, style, time, tour }) => {
    const isEmpty = booked === 0;
    const [opened, setOpened] = React.useState(false);
    const targetClassName = cx("tours-table_time-slot", isEmpty && empty);

    const openModal = React.useCallback(() => {
      isEmpty || setOpened(true);
    }, [isEmpty]);
    const closeModal = React.useCallback(() => {
      setOpened(false);
    }, []);
    const handlers = useCellHandlers(openModal);
    const tourTitle = `${tour.title} @ ${date} ${time}`;

    return (
      <div className={className}>
        <Target
          style={style}
          className={targetClassName}
          tabIndex={0}
          onKeyDown={handlers.onKeyDown}
          onClick={handlers.onChange}
        >
          <CellContent capacity={capacity} time={time} />
        </Target>
        <FixedSizeDrawer
          icon="info-sign"
          onClose={closeModal}
          title={<Title id={id}>{tourTitle}</Title>}
          {...dialogOptions}
          isOpen={opened}
        >
          <div className={Classes.DRAWER_BODY}>
            <div className={Classes.DIALOG_BODY}>
              {opened && <ListOfParticipant id={id} title={tourTitle} />}
            </div>
          </div>
        </FixedSizeDrawer>
      </div>
    );
  }
);

export default CellWithoutCustomer;
