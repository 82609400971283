/* eslint-disable @typescript-eslint/ban-types */
import { nanoid } from "nanoid";

const keys = new WeakMap<object, string>();

export default function keyFor(item: any, prefix: string | null = null) {
  let key: string;
  if (item instanceof Object) {
    if ("id" in item && item.id) {
      // The simplest case: item has an id
      return item.id;
    }

    if (!keys.has(item)) {
      // If an item appears for the first time, generate an id and save it to the cache.
      keys.set(item, nanoid());
    }

    // returns an id from the cache
    key = keys.get(item)!;
  } else if (typeof item === "symbol") {
    key = "symbol";
  } else {
    key = item;
  }

  return prefix !== null ? `${prefix}-${key}` : key;
}
