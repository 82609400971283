import { styled } from "linaria/react";
import { Overlay, Spinner } from "@blueprintjs/core";
import React from "react";

const StyledOverlaySpinner = styled(Overlay)`
  & > .bp3-overlay-content {
    width: 100%;
    height: 100%;
  }
`;

const OverlaySpinner = () => (
  <StyledOverlaySpinner isOpen={true}>
    <Spinner size={100} />
  </StyledOverlaySpinner>
);

export default OverlaySpinner;
