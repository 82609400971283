import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

import { addMinutes } from "_/utils/addMinutes";

import { ICheckpoint } from "./types";
import {
  GetParticipants_tour,
  GetParticipants_tour_checkpoints_orders,
  GetParticipants_tour_checkpoints_vouchers,
  GetParticipants_tour_prices,
} from "./__generated__/GetParticipants";

interface IDocumentProps {
  checkpoints: ICheckpoint[];
  summary?: Record<string, number>;
  title: string;
  tour: GetParticipants_tour;
}

const styles = StyleSheet.create({
  leftColumn: {
    width: "25%",
  },
  rightColumn: {
    width: "25%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  header: {
    fontSize: 18,
    fontWeight: "bold",
  },
  page: {
    backgroundColor: "#FFFFFF",
  },
  section: {
    margin: 10,
    padding: 10,
  },
});

const checkpointStyles = StyleSheet.create({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",

    fontWeight: "bold",

    padding: 10,
    borderBottom: "1px solid #aaaaaa",
  },
  table: {},
});

const orderStyles = StyleSheet.create({
  customer: {
    fontSize: 16,
  },
  customerRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  customerAndHotel: {
    width: "70%",
    padding: 10,
  },
  orderInfo: {
    ...styles.rightColumn,
    borderLeft: "1px solid #aaaaaa",
    padding: 10,
  },
  phone: {
    fontSize: 14,
  },
  hotel: {},
  row: {
    ...styles.row,
    justifyContent: "space-between",
    fontSize: 14,
  },
  table: {},
});

const priceStyles = StyleSheet.create({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

const Order: React.FC<{
  even: boolean;
  point: string;
  prices: GetParticipants_tour_prices[];
  order: GetParticipants_tour_checkpoints_orders;
  vouchers: GetParticipants_tour_checkpoints_vouchers[];
}> = ({ even, prices, order, vouchers }) => {
  const customer = order.customer;
  const voucher = vouchers.find((v) => v.customerId === customer.id);
  return (
    <View
      style={[
        orderStyles.row,
        { backgroundColor: even ? "#f5f7f9" : "#ffffff" },
      ]}
    >
      <View style={orderStyles.customerAndHotel}>
        <View style={orderStyles.customerRow}>
          <Text style={orderStyles.customer}>
            {customer.name}
            {voucher?.data.phone ? (
              <Text style={orderStyles.phone}> {voucher.data.phone}</Text>
            ) : null}
          </Text>
          <Text>{order.manager.name}</Text>
        </View>
        <View style={orderStyles.hotel}>
          <Text>{order.customer.hotel.name}</Text>
        </View>
      </View>
      <View style={orderStyles.orderInfo}>
        {prices
          .filter((price) => order.data[price.id] > 0)
          .map((price) => (
            <View key={price.id} style={priceStyles.wrapper}>
              <Text>{price.slug}</Text>
              <Text>{order.data[price.id]}</Text>
            </View>
          ))}
      </View>
    </View>
  );
};

const Checkpoint: React.FC<{
  baseTime: string;
  checkpoint: ICheckpoint;
  prices: GetParticipants_tour_prices[];
}> = ({ baseTime, checkpoint, prices }) => {
  if (checkpoint.orders.length === 0) return null;
  return (
    <View style={{ padding: 10 }}>
      <View style={checkpointStyles.header}>
        <Text>{checkpoint.point}</Text>
        <Text>{addMinutes(baseTime, checkpoint.delta)}</Text>
      </View>
      <View style={checkpointStyles.table}>
        {checkpoint.orders.map((order, idx) => (
          <Order
            key={order.id}
            even={idx % 2 === 0}
            point={checkpoint.point}
            prices={prices}
            order={order}
            vouchers={checkpoint.vouchers}
          />
        ))}
      </View>
    </View>
  );
};

const PDF: React.FC<IDocumentProps> = ({
  checkpoints,
  title,
  tour,
  summary,
}) => {
  const total = Object.values(summary ?? {}).reduce((acc, p) => acc + p, 0);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.header}>{title}</Text>
        </View>
        <View>
          {checkpoints.map((checkpoint) => (
            <Checkpoint
              key={checkpoint.key}
              checkpoint={checkpoint}
              baseTime={tour.time}
              prices={tour.prices}
            />
          ))}
        </View>
        <View style={[styles.section, styles.row]}>
          <View style={styles.rightColumn}>
            {tour.prices.map((price) => (
              <View key={price.id} style={priceStyles.wrapper}>
                <Text>{price.slug}</Text>
                <Text>{summary?.[price.id]}</Text>
              </View>
            ))}
          </View>
        </View>
        <View style={[styles.section, styles.row]}>
          <View style={styles.rightColumn}>
            <View key="total" style={priceStyles.wrapper}>
              <Text>Total</Text>
              <Text>{total}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PDF;
