import React from "react";
import OrderInfo from "./OrderInfo";
import { Icon } from "@blueprintjs/core";
import { styled } from "linaria/react";
import { GetPreVoucher_customer } from "./__generated__/GetPreVoucher";

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: var(--pt-grid-size);

  & > .bp3-icon {
    margin-right: var(--pt-grid-size);
  }
`;

const Tours = styled.div`
  margin: calc(var(--pt-grid-size) * 2) 0;
`;

const Confirmation: React.FC<{
  customer: GetPreVoucher_customer;
  customPrices: Record<string, number | undefined>;
  onCustomChange: (id: number, value: number) => void;
}> = ({ customer, customPrices, onCustomChange }) => {
  return (
    <>
      <InfoRow>
        <Icon icon={"person"} />
        <span>{customer.name}</span>
      </InfoRow>
      <InfoRow>
        <Icon icon={"home"} />
        <span>{customer.hotel.name}</span>
      </InfoRow>
      <Tours>
        {customer.orders.map((order) => (
          <OrderInfo
            customPrices={customPrices}
            onCustomChange={onCustomChange}
            key={order.id}
            order={order}
          />
        ))}
      </Tours>
    </>
  );
};

export default Confirmation;
