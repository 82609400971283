import React, { CSSProperties } from "react";
import { useLocalStorage } from "react-use";
import { css, cx } from "linaria";
import { styled } from "linaria/react";
import { memoize, orderBy } from "lodash";

import "./tours.css";

import Cell from "./Cell";
import { Alignment, Spinner, Switch } from "@blueprintjs/core";
import { $tours, fetchToursFx } from "_/pages/tours/effector/tours";
import { useStore } from "effector-react";
import { Tours_tours } from "_/pages/tours/effector/__generated__/Tours";

const condensedClass = css`
  &.tours-table_tour-row .tours-table_time-slot {
    margin-top: 0;
  }
`;

const strToMinutes = (str: string): number => {
  const [h, m] = str.split(":").map((p: any) => parseInt(p, 10));
  return h * 60 + m;
};

const getTourMeta = memoize((tour: Tours_tours) => {
  const timesSet = tour.slots.reduce((times, slot) => {
    return times.add(slot.time);
  }, new Set<string>());

  const strTimes = Array.from(timesSet).sort();
  const times = strTimes.map(strToMinutes);

  const base = times[0];
  const step =
    times.length < 2
      ? 60
      : Math.min(...times.slice(0, -1).map((a, idx) => times[idx + 1] - a));

  return {
    base,
    step,
  };
});

const useToursData = () => {
  const data = useStore($tours);
  const isLoading = useStore(fetchToursFx.pending);

  if (isLoading) return null;

  const dates = Array.from(
    data.reduce(
      (set, tour) => tour.slots.reduce((acc, slot) => acc.add(slot.date), set),
      new Set<string>()
    )
  ).sort();

  return { dates, tours: data };
};

const TableHeader: React.FC<{ dates: string[] }> = ({ dates }) => {
  return (
    <div className="tours-table_header">
      {dates.map((date) => (
        <h5 key={date}>{date.replace(/\d{4}-(\d{2})-(\d{2})/, "$2.$1")}</h5>
      ))}
    </div>
  );
};

const TourTitle = styled.h4`
  display: flex;
  justify-content: space-between;

  && > label {
    margin: 0;
  }
`;

const Tour: React.FC<{
  dates: string[];
  tour: Tours_tours;
}> = React.memo(({ dates, tour }) => {
  const [condensed, setCondensed] = useLocalStorage(
    `condensed-${tour.id}`,
    true
  );
  const triggerCondensed = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (args) => {
      setCondensed(args.target.checked);
    },
    [setCondensed]
  );
  const canBeWide = new Set(tour.slots.map((slot) => slot.time)).size > 1;
  if (tour.slots.length === 0) return null;

  return (
    <>
      <TourTitle>
        <div>{tour.title}</div>
        {canBeWide && (
          <Switch
            alignIndicator={Alignment.RIGHT}
            inline={true}
            checked={condensed}
            onChange={triggerCondensed}
            innerLabel="wide"
            innerLabelChecked="packed"
          />
        )}
      </TourTitle>
      <div className={cx("tours-table_tour-row", condensed && condensedClass)}>
        {dates.map((date) => {
          const { base, step } = getTourMeta(tour);
          let position = base;
          const slots = orderBy(
            tour.slots.filter((slot) => slot.date === date),
            "time"
          );

          return (
            <div key={date} className="tours-table_time-slot-container">
              {slots.map((item) => {
                const style = {
                  "--offset": (strToMinutes(item.time) - position) / step,
                } as CSSProperties;
                position = strToMinutes(item.time) + step;
                return (
                  <Cell
                    key={item.time}
                    id={item.id}
                    capacity={item.capacity - item.booked}
                    booked={item.booked}
                    reserve={item.reserve}
                    date={date}
                    time={item.time.substr(0, 5)}
                    style={style}
                    prices={tour.prices}
                    tour={tour}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
});

const Tours = () => {
  const data = useToursData();
  const numberOfColumns = data?.dates.length ?? 0;
  const styles = React.useMemo(
    () =>
      ({
        "--column-width": "100px",
        "--number-of-columns": numberOfColumns,
      } as CSSProperties),
    [numberOfColumns]
  );
  if (data === null) return <Spinner size={100} />;
  const { dates, tours } = data;
  const headers = <TableHeader dates={dates} />;
  return (
    <div className="tours-table" style={styles}>
      {headers}
      {tours.map((tour) => (
        <Tour key={tour.id} tour={tour} dates={dates} />
      ))}
      {headers}
    </div>
  );
};

export default Tours;
