import { Button, Dialog } from "@blueprintjs/core";
import React from "react";
import { useStore } from "effector-react";
import { $customer } from "_/pages/tours/effector/customer";
import { $orders } from "_/pages/tours/effector/orders";
import VoucherDialog from "_/components/CustomerPanel/SelectedCustomer/Voucher/VoucherDialog";

const dialogOptions = {
  autoFocus: true,
  canEscapeKeyClose: true,
  canOutsideClickClose: true,
  enforceFocus: true,
  usePortal: true,
};

const Voucher = () => {
  const [opened, setOpened] = React.useState(false);
  const data = useStore($orders);
  const customer = useStore($customer);
  const sum = data.reduce((acc, order) => acc + parseFloat(order.cost), 0);
  const closeModal = React.useCallback(() => setOpened(false), []);
  if (!customer) return null;
  return (
    <>
      <Button
        disabled={data.length === 0}
        minimal={true}
        onClick={() => setOpened(true)}
        text={
          <span>
            Total orders: <strong>{data.length}</strong> on{" "}
            <strong>€{sum}</strong>
          </span>
        }
      />
      <Dialog
        icon="info-sign"
        onClose={() => setOpened(false)}
        title={customer.name}
        {...dialogOptions}
        isOpen={opened}
      >
        <VoucherDialog
          closeModal={closeModal}
          customer={customer}
          orders={data}
        />
      </Dialog>
    </>
  );
};

export default Voucher;
