import { createEffect, createEvent, createStore, restore } from "effector";
import { gql } from "@apollo/client";
import client from "_/graphql/client";
import {
  SignIn,
  SignInVariables,
} from "_/components/Auth/__generated__/SignIn";
import { showToast } from "_/components/Toaster";
import { GetSeller } from "_/components/__generated__/GetSeller";

interface ILoginArgs {
  company?: string;
  login: string;
  password: string;
}

const getSeller = gql`
  query GetSeller {
    seller {
      name
    }
  }
`;

const signIn = gql`
  mutation SignIn($company: String!, $login: String!, $password: String!) {
    signIn(company: $company, login: $login, password: $password)
  }
`;

export const logout = createEvent();
logout.watch(() => {
  window.localStorage.removeItem("auth-token");
});

export const loginFx = createEffect(async (args: ILoginArgs) => {
  const { data } = await client.mutate<SignIn, SignInVariables>({
    mutation: signIn,
    variables: {
      ...args,
      company: args.company || "EasyTravel",
    },
  });

  if (!data || data.signIn === null) {
    throw new Error("User not found");
  }

  return data.signIn;
});

loginFx.fail.watch(({ error }) => {
  showToast({
    icon: "warning-sign",
    intent: "danger",
    message: error.message,
  });
});

export const $authToken = createStore<string | null>(
  window.localStorage.getItem("auth-token") ?? null
)
  .on(loginFx.doneData, (state, token) => {
    window.localStorage.setItem("auth-token", token);
    return token;
  })
  .reset(logout);

export const loadUserNameFx = createEffect(async () => {
  const { data } = await client.query<GetSeller>({
    query: getSeller,
  });

  if (!data || !data.seller) {
    throw new Error("User not found");
  }

  return data.seller.name;
});

$authToken.watch((token) => {
  if (!token) return;
  void loadUserNameFx();
});

loadUserNameFx.fail.watch(() => logout());

export const $currentUserName = restore(loadUserNameFx, null);
